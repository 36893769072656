import * as Yup from 'yup';
import {
  fieldType,
  formQuestionTypeOptions,
  microservices,
  formBranchingConditionTypes,
  formBranchingConcatenators,
  formRecipientStatus,
  formDisplayMode,
  formCategoryOptions,
  formAccessLevels,
} from '@config/constants';
import { formsUrls } from '@config/routes';
import getDropdownForm from '@dropdown-forms/getDropdownForm';
import { formatToUTCDateTime } from '@config/functions/helperFunctions';

const {
  groupsUrls,
  questionsUrls,
  questionOptionsUrls,
  questionGroupsUrls,
  questionOptionGroupsUrls,
} = formsUrls;

export const formsMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    onForm: true,
    width: 300,
    type: fieldType.LINK,
    to: '/forms/',
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
    gridStyle: 6,
  },
  {
    field: 'title',
    headerName: 'Title',
    onForm: true,
    width: 300,
    type: fieldType.Text,
    editable: true,
    value: data ? data.title : null,
    initialValue: '',
    validate: Yup.string().required(),
    gridStyle: 6,
  },
  {
    field: 'description',
    headerName: 'Description',
    onForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 12,
    row: 3,
  },
  {
    field: 'displayMode',
    headerName: 'Display mode',
    type: fieldType.SELECT,
    editable: true,
    width: 140,
    onForm: true,
    options: formDisplayMode,
    initialValue: formDisplayMode[0]?.value,
    value: data ? data.displayMode : null,
    validate: Yup.string(),
    gridStyle: 3.5,
  },
  {
    width: 180,
    field: 'accessLevel',
    headerName: 'Access level',
    type: fieldType.SELECT,
    editable: true,
    options: formAccessLevels,
    initialValue: formAccessLevels[0]?.value,
    value: data ? data.accessLevel : null,
    validate: Yup.string(),
    onForm: true,
  },
  {
    field: 'category',
    headerName: 'Category',
    type: fieldType.SELECT,
    editable: true,
    width: 140,
    onForm: true,
    options: formCategoryOptions,
    initialValue: formCategoryOptions[0].value,
    value: data ? data.category : null,
    validate: Yup.string(),
    gridStyle: 3.5,
  },
  {
    field: 'isPublished',
    headerName: 'Published',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.isPublished : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
    gridStyle: 2,
  },
  {
    field: 'allowMultipleSubmissions',
    headerName: 'Allow Multiple Submissions',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.allowMultipleSubmissions : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
    gridStyle: 3,
  },
  {
    field: 'closingMessage',
    headerName: 'Closing message',
    width: 300,
    type: fieldType.MULTILINE,
    editable: true,
    value: data?.closingMessage,
    initialValue: 'Thank you',
    onForm: true,
    validate: Yup.string().max(2500),
    gridStyle: 5,
  },
  {
    field: 'showFirstName',
    headerName: 'Show First Name',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.showFirstName : null,
    initialValue: true,
    validate: Yup.boolean().nullable(),
    gridStyle: 2,
  },
  {
    field: 'showLastName',
    headerName: 'Show Last Name',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.showLastName : null,
    initialValue: true,
    validate: Yup.boolean().nullable(),
    gridStyle: 2,
  },
  {
    field: 'showEmail',
    headerName: 'Show Email',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.showEmail : null,
    initialValue: true,
    validate: Yup.boolean().nullable(),
    gridStyle: 1.5,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    type: fieldType.MULTILINE,
    onForm: true,
    width: 200,
    editable: true,
    value: data?.tags ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    gridStyle: 1.5,
  },
];

export const questionsMeta = ({
  data,
  formId,
  nextOrder,
  queryClient,
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 80,
    onAccordionForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    validate: Yup.number().required(),
    onDetailCard: false,
  },
  {
    field: 'question',
    headerName: 'Question',
    onForm: true,
    width: 600,
    type: fieldType.MULTILINE,
    to: '/questions/',
    editable: true,
    value: data ? data.question : null,
    initialValue: '',
    onAccordionForm: true,
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data?.description ?? null,
    initialValue: '',
    width: 300,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    hideColumn: true,
  },
  {
    field: 'isRequired',
    headerName: 'Required?',
    type: fieldType.BOOLEAN,
    width: 220,
    editable: true,
    initialValue: true,
    onAccordionForm: true,
    value: data?.isRequired ?? null,
    validate: Yup.boolean().default(true),
  },
  {
    field: 'groupNames',
    headerName: 'Groups',
    width: 180,
    type: fieldType.MULTILINE,
    editable: false,
    value: data ? data.groupNames : null,
    initialValue: '',
    onAccordionForm: true,
    onDetailCard: false,
    validate: Yup.string(),
  },
  {
    gridStyle: 2,
    field: 'type',
    headerName: 'Type',
    type: fieldType.SELECT,
    editable: true,
    width: 160,
    options: formQuestionTypeOptions,
    onAccordionForm: true,
    initialValue: formQuestionTypeOptions[0].value,
    value: data ? data.type : null,
    validate: Yup.string(),
  },
  {
    field: 'includeOthersField',
    headerName: 'Add other field',
    type: fieldType.BOOLEAN,
    width: 220,
    editable: true,
    initialValue: false,
    onAccordionForm: true,
    hideColumn: true,
    onDetailCard: data?.type === 'MultipleChoice',
    value: data ? data.includeOthersField : null,
    validate: Yup.boolean().nullable(),
  },
  {
    gridStyle: 2,
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const questionOptionGroupMeta = ({ data } = {}) => [
  {
    field: 'name',
    headerName: 'Name',
    to: '/question-option-groups/',
    onAccordionForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 220,
    editable: true,
    value: data ? data.tags : null,
    initialValue: '',
    onForm: true,
    validate: Yup.string(),
  },
  {
    field: 'questionId',
    headerName: 'Question',
    width: 280,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: questionsUrls.list,
    requestKey: `${data?.id}-question-form`,
    validate: Yup.mixed(),
    initialValue: data?.question
      ? {
          id: data?.questionId,
          label: `${data.question?.order}. ${data.question?.question}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.order}. ${row?.question}`,
    }),
    urlParams: data?.question ? `&formId=${data?.question?.formId}` : null,
    hideColumn: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const questionGroupScoreRangeMeta = ({ data, queryClient } = {}) => [
  {
    field: 'questionOptionGroup',
    headerName: 'Question Option Group',
    width: 280,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: questionOptionGroupsUrls.list,
    requestKey: `${data?.id}-question-option-group-scores`,
    validate: Yup.mixed(),
    initialValue: data?.questionOptionGroup
      ? {
          id: data?.questionOptionGroupId,
          label: `${data?.questionOptionGroup?.name ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: row?.name,
    }),
    enabled: !!data?.questionOptionGroup?.questionId,
    urlParams: data?.questionOptionGroup?.questionId
      ? `&questionId=${data?.questionOptionGroup?.questionId}`
      : '',
    orderingCol: 'order',
    hideColumn: true,
    gridStyle: 3,
  },
  {
    field: 'minScore',
    headerName: 'Min Score %',
    getHref: (r) => `/question-group-score-ranges/${r.id}`,
    width: 136,
    onAccordionForm: true,
    editable: true,
    value: data?.minScore,
    initialValue: '1',
    validate: Yup.number()
      .positive('Must be a positive number')
      .required('Max score is required')
      .test(
        'is-less-than-max',
        'Min score must be less than Max score',
        function (value) {
          const { maxScore } = this.parent;
          return value < maxScore;
        }
      )
      .typeError('Must be a number'),
    gridStyle: 3,
  },
  {
    field: 'maxScore',
    headerName: 'Max Score %',
    width: 136,
    onAccordionForm: true,
    editable: true,
    value: data?.maxScore,
    initialValue: 100,
    validate: Yup.number()
      .max(100)
      .positive('Must be a positive number')
      .required('Max score is required')
      .test(
        'is-greater-than-min',
        'Max score must be greater than Min score',
        function (value) {
          const { minScore } = this.parent;
          return value > minScore;
        }
      )
      .typeError('Must be a number'),
    gridStyle: 3,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
    gridStyle: 3,
  },
  {
    field: 'attributeOne',
    headerName: 'Attribute One',
    onAccordionForm: true,
    editable: true,
    value: data?.attributeOne ?? null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 4,
  },
  {
    field: 'attributeTwo',
    headerName: 'Attribute Two',
    onAccordionForm: true,
    editable: true,
    value: data?.attributeTwo ?? null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 4,
  },
  {
    field: 'attributeThree',
    headerName: 'Attribute Three',
    onAccordionForm: true,
    editable: true,
    value: data?.attributeThree ?? null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 4,
  },
  {
    field: 'conclusion',
    headerName: 'Conclusion',
    onAccordionForm: true,
    editable: true,
    value: data?.conclusion ?? null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 12,
    rows: 4,
  },
  {
    field: 'strengths',
    headerName: 'Strengths',
    onAccordionForm: true,
    editable: true,
    value: data?.strengths ?? null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 6,
    rows: 15,
  },
  {
    field: 'weaknesses',
    headerName: 'Weaknesses',
    onAccordionForm: true,
    editable: true,
    value: data?.weaknesses ?? null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 6,
    rows: 15,
  },
  {
    field: 'workplaceFit',
    headerName: 'Where you belong in the workplace?',
    onAccordionForm: true,
    editable: true,
    value: data ? data.workplaceFit : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 6,
    rows: 8,
  },
  {
    field: 'recommendations',
    headerName: 'Be your best ',
    onAccordionForm: true,
    editable: true,
    value: data ? data.recommendations : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
    gridStyle: 6,
    rows: 8,
  },
];

export const questionOptionMeta = ({
  data,
  nextOrder,
  type,
  question,
  queryClient,
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 80,
    onAccordionForm: true,
    editable: true,
    value: data && data.order,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    validate: Yup.number().required(),
    onDetailCard: false,
  },
  {
    field: 'option',
    headerName: 'Option',
    onAccordionForm: true,
    width: 300,
    type: fieldType.LINK,
    to: '/question-options/',
    editable: true,
    value: data ? data.option : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'questionOptionGroupId',
    headerName: 'Question Option Group',
    width: 280,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: questionOptionGroupsUrls.list,
    requestKey: `${data?.id}-question-option-group`,
    validate: Yup.mixed(),
    hideColumn: type === 'Likert' ? false : true,
    onDetailCard: data?.question?.type === 'Likert' ?? true,
    initialValue: data?.questionOptionGroupId
      ? {
          id: data?.questionOptionGroupId,
          label: `${data.questionOptionGroup?.name}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    urlParams: question
      ? `&questionId=${question}`
      : `&questionId=${data?.questionId}`,
    getDropdownForm: () => ({
      refetch: () => {
        queryClient.invalidateQueries([`${data?.id}-option-group`]);
      },
      Form: getDropdownForm({
        microservice: microservices.Forms.name,
        model: microservices.Forms.models.questionOptionGroup,
      }),
    }),
  },
  {
    field: 'isCorrect',
    headerName: 'Is Correct?',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.isCorrect : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const responseMeta = ({ data } = {}) => [
  {
    field: 'firstName',
    headerName: 'First name',
    onForm: true,
    width: 160,
    type: fieldType.LINK,
    to: '/form-responses/',
    editable: true,
    value: data ? data.firstName : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    onForm: true,
    width: 160,
    editable: true,
    value: data ? data.lastName : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'email',
    headerName: 'Email address',
    onForm: true,
    width: 220,
    editable: true,
    value: data ? data.email : null,
    initialValue: '',
    validate: Yup.string().required(),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: true,
    width: 140,
    onAccordionForm: true,
    onForm: true,
    options: formRecipientStatus,
    initialValue: formRecipientStatus[0].value,
    value: data ? data.status : null,
    validate: Yup.string(),
  },
  {
    field: 'expiryDate',
    headerName: 'Expiry date',
    type: fieldType.DATE_TIME,
    width: 160,
    editable: false,
    value: data?.recipient
      ? formatToUTCDateTime(new Date(data?.recipient?.expiryDate))
      : null,
    initialValue: null,
    validate: Yup.string().default(formatToUTCDateTime(new Date())).nullable(),
    onAccordionForm: true,
  },
  {
    field: 'sendEmail',
    headerName: 'Send Email',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.sendEmail : null,
    initialValue: true,
    validate: Yup.boolean().nullable(),
    hideColumn: true,
    onDetailCard: true,
  },
  {
    field: 'recipientId',
    headerName: 'Recipient',
    type: fieldType.BOOLEAN,
    width: 140,
    editable: false,
    value: data ? (data.recipientId ? true : false) : null,
    initialValue: false,
    validate: Yup.boolean().nullable(),
  },
  {
    field: 'utmCampaign',
    headerName: 'UTM Campaign',
    onForm: false,
    onAccordionForm: false,
    width: 220,
    editable: true,
    value: data?.utmCampaign ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utmTerm',
    headerName: 'UTM Term',
    onForm: false,
    onAccordionForm: false,
    width: 220,
    editable: true,
    value: data?.utmTerm ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utmContent',
    headerName: 'UTM Content',
    onForm: false,
    onAccordionForm: false,
    width: 220,
    editable: true,
    value: data?.utmContent ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utmMedium',
    headerName: 'UTM Medium',
    onForm: false,
    onAccordionForm: false,
    width: 220,
    editable: true,
    value: data?.utmMedium ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'utmSource',
    headerName: 'UTM Source',
    onForm: false,
    onAccordionForm: false,
    width: 220,
    editable: true,
    value: data?.utmSource ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'tags',
    headerName: 'Tags',
    type: fieldType.MULTILINE,
    onForm: true,
    width: 200,
    editable: true,
    value: data?.tags ?? null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
  {
    field: 'updatedAt',
    headerName: 'Date updated',
    width: 180,
    value: data && data.updatedAt ? new Date(data.updatedAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const groupMeta = ({
  formId,
  data,
  hideParentGroupColumn,
  nextOrder,
} = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 136,
    editable: true,
    value: data ? data.order : null,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    onAccordionForm: true,
    width: 250,
    editable: true,
    value: data ? data.name : null,
    initialValue: '',
    validate: Yup.string().required(),
    to: '/form-groups/',
  },
  {
    field: 'parentGroupId',
    headerName: 'Parent Group',
    width: 280,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: groupsUrls.list,
    requestKey: `${data?.id}-question-parent-groups`,
    validate: Yup.mixed(),
    initialValue: data?.parentGroupId
      ? {
          id: data?.parentGroupId,
          label: `${data?.parentGroup?.name ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.order}. ${row?.name}`,
    }),
    urlParams: formId ? `&formId=${formId}` : '',
    orderingCol: 'order',
    hideColumn: hideParentGroupColumn,
  },
  {
    field: 'description',
    headerName: 'Description',
    onAccordionForm: true,
    editable: true,
    value: data ? data.description : null,
    initialValue: '',
    width: 400,
    type: fieldType.MULTILINE,
    validate: Yup.string(),
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const branchingConditionMeta = ({ data, nextOrder, formId } = {}) => [
  {
    field: 'order',
    headerName: 'Order',
    width: 136,
    editable: true,
    value: data ? data.order : null,
    initialValue: nextOrder ? nextOrder + 1 : 1,
    validate: Yup.number().required(),
    onAccordionForm: true,
  },
  {
    field: 'concatenator',
    headerName: 'Concatenator',
    type: fieldType.SELECT,
    editable: true,
    onAccordionForm: true,
    width: 120,
    onForm: true,
    options: formBranchingConcatenators,
    initialValue: null,
    value: data?.concatenator ?? null,
    validate: Yup.string().nullable(),
  },
  {
    field: 'currentGroupId',
    headerName: 'Current Group',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: groupsUrls.list,
    requestKey: `${data?.id}-form-current-groups`,
    validate: Yup.mixed(),
    initialValue: data?.currentGroupId
      ? {
          id: data?.currentGroupId,
          label: `${data?.currentGroup?.name ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    urlParams: formId ? `&formId=${formId}` : '',
  },
  {
    field: 'sourceGroupId',
    headerName: 'Source Group',
    width: 180,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: groupsUrls.list,
    requestKey: `${data?.id}-form-source-groups`,
    validate: Yup.mixed(),
    initialValue: data?.sourceGroupId
      ? {
          id: data?.sourceGroupId,
          label: `${data?.sourceGroup?.name ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    urlParams: formId ? `&formId=${formId}` : '',
  },
  {
    field: 'questionId',
    headerName: 'Question',
    width: 280,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: questionGroupsUrls.list,
    requestKey: `${data?.id}-form-questions`,
    validate: Yup.mixed(),
    initialValue: data?.questionId
      ? {
          id: data?.questionId,
          label: `${data?.question?.question ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.questionId,
      label: `${row?.question?.question}`,
    }),
    urlParams: data?.sourceGroupId ? `&groupId=${data?.sourceGroupId}` : '',
  },
  {
    field: 'questionOptionGroupId',
    headerName: 'Option Group',
    width: 180,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: questionOptionGroupsUrls.list,
    requestKey: `${data?.id}-question-option-groups`,
    validate: Yup.mixed(),
    initialValue: data?.questionOptionGroupId
      ? {
          id: data?.questionOptionGroupId,
          label: `${data?.questionOptionGroup?.name ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    urlParams: data?.questionId ? `&questionId=${data?.questionId}` : '',
  },
  {
    field: 'type',
    headerName: 'Operator',
    type: fieldType.SELECT,
    editable: true,
    onAccordionForm: true,
    width: 90,
    onForm: true,
    options: formBranchingConditionTypes,
    initialValue: formBranchingConditionTypes[0].value,
    value: data ? data?.type : null,
    validate: Yup.string().required(),
  },
  {
    field: 'questionOptionId',
    headerName: 'Question Option',
    width: 280,
    editable: false,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: questionOptionsUrls.list,
    requestKey: `${data?.id}-form-question-options`,
    validate: Yup.mixed(),
    initialValue: data?.questionOptionId
      ? {
          id: data?.questionOptionId,
          label: `${data?.questionOption?.option ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.option}`,
    }),
    urlParams: data?.questionId ? `&questionId=${data?.questionId}` : '',
  },
  {
    field: 'staticValue',
    headerName: 'Value',
    onAccordionForm: true,
    editable: true,
    value: data ? data.staticValue : null,
    initialValue: '',
    width: 180,
    type: fieldType.Text,
    validate: Yup.string(),
  },
  {
    field: 'targetGroupId',
    headerName: 'Target Group',
    width: 280,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: groupsUrls.list,
    requestKey: `${data?.id}-form-target-groups`,
    validate: Yup.mixed(),
    initialValue: data?.targetGroupId
      ? {
          id: data?.targetGroupId,
          label: `${data?.targetGroup?.name ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    urlParams: formId ? `&formId=${formId}` : '',
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.createdAt ? new Date(data.createdAt) : null,
    onAccordionForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const questionGroupMeta = ({
  data,
  hideGroup,
  hideQuestion,
  formId,
} = {}) => [
  {
    field: 'questionId',
    headerName: 'Question',
    width: 280,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: questionsUrls.list,
    requestKey: `${data?.id}-question-form`,
    validate: Yup.mixed(),
    initialValue: data?.questionId
      ? {
          id: data?.questionId,
          label: `${data.questionId?.question}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.question}`,
    }),
    hideColumn: hideQuestion,
    urlParams: formId ? `&formId=${formId}` : '',
    getHref: (r) => `/questions/${r?.questionId?.id}`,
  },
  {
    field: 'groupId',
    headerName: 'Group',
    width: 280,
    editable: true,
    onAccordionForm: true,
    type: fieldType.AUTOCOMPLETE_V2,
    fetchUrl: groupsUrls.list,
    requestKey: `${data?.id}-question-groups`,
    validate: Yup.mixed(),
    initialValue: data?.groupId
      ? {
          id: data?.groupId,
          label: `${data?.group?.name ?? 'No name'}`,
        }
      : null,
    renderRow: (row) => ({
      id: row?.id,
      label: `${row?.name}`,
    }),
    hideColumn: hideGroup,
    urlParams: formId ? `&formId=${formId}` : '',
    getHref: (r) => `/form-groups/${r?.groupId?.id}`,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data && data.created_at ? new Date(data.created_at) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];

export const formRecipientMeta = ({ data } = {}) => [
  {
    field: 'firstName',
    headerName: 'First name',
    width: 160,
    editable: false,
    value: data ? data.firstName : null,
    initialValue: '',
    validate: Yup.string(),
    getHref: (row) => `/form-responses/${row?.response?.id}`,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 160,
    editable: false,
    value: data ? data.lastName : null,
    initialValue: '',
    validate: Yup.string(),
  },
  {
    field: 'email',
    headerName: 'Email',
    editable: false,
    value: data ? data.email : null,
    initialValue: '',
    width: 220,
    validate: Yup.string().email().required(),
  },
  {
    field: 'status',
    headerName: 'Status',
    type: fieldType.SELECT,
    editable: false,
    width: 140,
    options: formRecipientStatus,
    initialValue: formRecipientStatus[0].value,
    value: data ? data.status : null,
    validate: Yup.string(),
  },
  {
    field: 'expiryDate',
    headerName: 'Expiry date',
    type: fieldType.DATE_TIME,
    width: 160,
    editable: true,
    value: data?.expiryDate
      ? formatToUTCDateTime(new Date(data.expiryDate))
      : null,
    initialValue: null,
    validate: Yup.string().default(formatToUTCDateTime(new Date())).nullable(),
    onAccordionForm: true,
  },
  {
    field: 'sendEmail',
    headerName: 'Send Email',
    onAccordionForm: true,
    type: fieldType.BOOLEAN,
    width: 140,
    editable: true,
    value: data ? data.sendEmail : null,
    initialValue: true,
    validate: Yup.boolean().nullable(),
    hideColumn: true,
    onDetailCard: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date created',
    width: 180,
    value: data?.createdAt ? new Date(data.createdAt) : null,
    onForm: false,
    editable: false,
    type: fieldType.DATE_TIME,
  },
];
